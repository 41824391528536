<script lang="ts">
    export let data;

    let recentMedia = data.recentMedia;
</script>

<div class="profile-widgets-area pb-3">
    <div class="card recent-media rounded-lg">
        <div class="card-body m-0 pb-0"></div>
        <h5 class="card-title mb-0 pl-3">Recent</h5>
        <div class="card-body">
            {#if recentMedia && recentMedia.length > 0}
                <div class="flex space-x-3">
                    {#each recentMedia as media}
                        <a href="{media.path}" rel="mswp">
                            <img
                                src="{media.thumbnail}"
                                alt="{`Thumbnail for ${media.filename}`}"
                                class="mb-md-2 mb-lg-2 mb-xl-0 img-fluid mb-2 rounded"
                            />
                        </a>
                    {/each}
                </div>
            {:else}
                <p class="m-0">Latest media not available.</p>
            {/if}
        </div>
    </div>

    <!-- TODO: rest of widgets -->
</div>
